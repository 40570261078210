import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import LayoutOne from '../../layouts/LayoutOne';
import Breadcrumb from '../../wrappers/breadcrumb/Breadcrumb';
import { useTranslation } from 'react-i18next';
import useLocaleConfigSelectors from '../../helpers/useLocaleConfigSelectors.js';
import { Helmet } from 'react-helmet';

const NotFound = ({ reason }) => {
	const { t } = useTranslation();
	const { pathname } = useLocation();
	const { getSiteName } = useLocaleConfigSelectors();
	const siteName = getSiteName;

	// Get appropriate error message based on reason
	// Only use specific messages if reason is provided
	const getErrorMessage = () => {
		if (!reason) {
			return t('Sorry, the page was not found');
		}

		switch (reason) {
			case 'rewriting-failed':
				return t('The URL could not be resolved');
			case 'product-fetch-failed':
				return t('The requested product could not be found');
			case 'category-fetch-failed':
				return t('The requested category could not be found');
			case 'brand-fetch-failed':
				return t('The requested brand could not be found');
			case 'content-fetch-failed':
				return t('The requested content could not be found');
			case 'content-type-not-found':
				return t('The requested content type is not supported');
			case 'route-not-found':
			default:
				return t('Sorry, the page was not found');
		}
	};

	return (
		<Fragment>
			<Helmet>
				<title>{t('Not found')} | {siteName} </title>
				<meta
					name="description"
					content={t('Not found') + ' | ' + siteName}
				/>
			</Helmet>
			<BreadcrumbsItem to={process.env.PUBLIC_URL + '/'}>{t('Homepage')}</BreadcrumbsItem>
			<BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>
				{t('Not found')}
			</BreadcrumbsItem>
			<LayoutOne>
				<Breadcrumb />
				<div className="error-area pt-40 pb-100">
					<div className="container">
						<div className="row justify-content-center">
							<div className="col-xl-7 col-lg-8 text-center">
								<div className="error">
									<h1>404</h1>
									<h2>{getErrorMessage()}</h2>
									<Link to={process.env.PUBLIC_URL + '/'} className="error-btn mt-30">
										{t('Back to homepage')}
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
			</LayoutOne>
		</Fragment>
	);
};

NotFound.propTypes = {
	location: PropTypes.object,
	reason: PropTypes.string,
};

export default NotFound;
